import React from "react";
import { useHistory } from "react-router-dom";
import { Menu } from "antd";
import "./index.scss";
import { ExportOutlined } from "@ant-design/icons";
import marketing from "../assets/images/marcketing.svg";
import sales from "../assets/images/salse.svg";
import product from "../assets/images/product.svg";
import hr from "../assets/images/hr.svg";
import finance from "../assets/images/hr.svg";
import service from "../assets/images/service.svg";
import SubMenu from "antd/lib/menu/SubMenu";
import { useTranslation } from "react-i18next";

interface SideNavBarProps {
	collapsed: (arg0: boolean) => void;
}

export const SideNavBarComponent: React.FC<SideNavBarProps> = (props) => {
	const { t } = useTranslation();
	const history = useHistory();

	const handleMenuClick = (e: any) => {
		history.push(e.key);
		props.collapsed(false);
	};

	return (
		<Menu style={{ width: "200px" }} theme="light" mode="inline" defaultSelectedKeys={["/"]} onClick={handleMenuClick}>
			<SubMenu className="d-none d-sm-block d-md-block d-lg-block d-xl-none" style={{ color: "#6A6F80" }} key="sub1" icon={<img src={marketing} alt="icon" className="pr-2" />} title="Marketing">
				<Menu.Item key="/marketing/tactical">{t("layout_full_page.tactical")}</Menu.Item>
				<Menu.Item key="/marketing/operational">{t("layout_full_page.operational")}</Menu.Item>
			</SubMenu>
			<Menu.Item className="col-auto d-none d-sm-none d-md-none d-lg-none d-xl-block" key="/" icon={<img src={marketing} alt="icon" />}>
				{t("layout_full_page.marketing")}
			</Menu.Item>
			<Menu.Item disabled={true} key="/sales" icon={<img src={sales} alt="icon" />}>
				{t("layout_full_page.salse")}
			</Menu.Item>
			<Menu.Item disabled={true} key="3" icon={<img src={product} alt="icon" />}>
				{t("layout_full_page.product")}
			</Menu.Item>
			<Menu.Item disabled={true} key="4" icon={<img src={finance} alt="icon" />}>
				{t("layout_full_page.finance")}
			</Menu.Item>
			<Menu.Item disabled={true} key="5" icon={<img src={hr} alt="icon" />}>
				{t("layout_full_page.human_resource")}
			</Menu.Item>
			<Menu.Item disabled={true} key="6" icon={<img src={service} alt="icon" />}>
				{t("layout_full_page.service_and_support")}
			</Menu.Item>
			<Menu.Item key="/uploadfile" icon={<ExportOutlined style={{ fontSize: "1.2rem" }} />}>
				{t("layout_full_page.configuration")}
			</Menu.Item>
		</Menu>
	);
};
