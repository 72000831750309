import React, { useReducer, useState } from "react";
import { createContext } from "use-context-selector";
import { IFilterValue } from "../models/filter.model";
interface FilterContextData {
  filterObjects: IFilterValue[];
  appliedFilterObjects: IFilterValue[];
  dispatchFilterObjects: React.Dispatch<FilterDispatchAction>;
  dispatchAppliedFilterObjects: React.Dispatch<FilterDispatchAction>;
  disableApply: boolean;
  setDisableApply: React.Dispatch<boolean>;
}

interface FilterDispatchAction {
  type: string;
  payload?: any;
}

const filterReducer = (
  allFilterValues: IFilterValue[],
  action: FilterDispatchAction
): IFilterValue[] => {
  let payloadFilterValues: IFilterValue[] =
    action.payload instanceof Array ? action.payload : [action.payload];
  let needToAddValues;

  switch (action.type) {
    case "init":
      return payloadFilterValues;
    case "add":
      needToAddValues = payloadFilterValues.filter((x) => {
        return (
          allFilterValues.findIndex(
            (y) => y.id === x.id && y.type === x.type
          ) === -1
        );
      });
      return [...allFilterValues, ...needToAddValues];
    case "addSingleValue":
      const tmpTypes: string[] = payloadFilterValues.map(
        (x) => x.type as string
      );
      return [
        ...allFilterValues.filter(
          (x) => tmpTypes.indexOf(x.type as string) === -1
        ),
        ...payloadFilterValues,
      ];
    case "remove":
      return allFilterValues.filter(
        (x) =>
          payloadFilterValues.findIndex(
            (y) => y.id === x.id && y.type === x.type
          ) === -1
      );
    case "addRemove":
      needToAddValues = payloadFilterValues.filter((x) => {
        return (
          x.action === "add" &&
          allFilterValues.findIndex(
            (y) => y.id === x.id && y.type === x.type
          ) === -1
        );
      });
      return allFilterValues.filter(
        (x) =>
          payloadFilterValues
            .filter((x1) => x1.action === "remove")
            .findIndex((y) => y.id === x.id && y.type === x.type) === -1
      );
    case "toggle":
      const removedFilterValues = allFilterValues.filter(
        (x) =>
          payloadFilterValues.findIndex(
            (y) => y.id === x.id && y.type === x.type
          ) === -1
      );
      return [...removedFilterValues, ...payloadFilterValues];
    case "initType":
      const type = action.payload.type;
      const filterValues = action.payload.values.map((x: any) => {
        x.type = type;
        return x;
      });
      const removedTypeValues = allFilterValues.filter((x) => x.type !== type);
      return [...removedTypeValues, ...filterValues];
    case "clear":
      const types: string[] =
        action.payload instanceof Array ? action.payload : [action.payload];
      const clearFilterValues = allFilterValues.filter(
        (x) => types.indexOf(x.type as string) === -1
      );
      return [...clearFilterValues, ...[]];
    case "clearAll":
      return [
        {
          id: "clearAll",
          type: "clearAll",
          value: "clearAll",
        },
        ...allFilterValues.filter((x) => x.type === "filter_identifier_tactical" || x.type === "filter_identifier_operational")
      ];
    default:
      return allFilterValues;
  }
};

export const FilterContext = createContext({} as FilterContextData);

export const FilterProvider: React.FC = (props) => {
  const [filterObjects, dispatchFilterObjects] = useReducer(
    filterReducer,
    [] as IFilterValue[]
  );
  const [appliedFilterObjects, dispatchAppliedFilterObjects] = useReducer(
    filterReducer,
    [] as IFilterValue[]
  );
  const [disableApply, setDisableApply] = useState<boolean>(false);

  const context = {
    filterObjects: filterObjects,
    appliedFilterObjects: appliedFilterObjects,
    dispatchFilterObjects: dispatchFilterObjects,
    dispatchAppliedFilterObjects: dispatchAppliedFilterObjects,
    disableApply: disableApply,
    setDisableApply: setDisableApply,
  };

  return (
    <FilterContext.Provider value={context}>
      {props.children}
    </FilterContext.Provider>
  );
};
