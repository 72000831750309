export const API_END_POINTS = {
    dashboard_tactical: "dashboard/tactical/requires-attention",
    get_attention_summary_details: "dashboard/tactical/attention-summary",
    opportunities_focus: "dashboard/tactical/opportunities-focus",
    quarterly_trend: "dashboard/tactical/quarterly-trends",
    segment: "dashboard/tactical/segment",
    region: "/dashboard/tactical/region",
    pipeline_status: "/dashboard/tactical/pipeline-status/all",
    trend_cards: "/dashboard/tactical/trends-card",
    pipeline_status_details: "/dashboard/tactical/pipeline-status/",
    opportunity_analysis: "/dashboard/tactical/opportunity-analysis",
    channel_details: "/dashboard/operational/channel-details",
    funnel_details: "/dashboard/operational/funnel-details",
    get_updated_time: "/dashboard/tactical/updated-time",
    like_dislike_message: "/dashboard/tactical/requires-attention-log",
    opportunity_deal_medium: "/dashboard/operational/opportunity-deal-medium",
    outreach_activity: "/dashboard/operational/outreach-activity",
    segment_details_table: "/dashboard/operational/segment-details",
    regional_view: "/dashboard/strategic/regional-view",
    downloadXls: "dashboard/operational/downloadxls",
    funnel_details_table: "dashboard/operational/funnel-details-table",
    insights : "dashboard/operational/insights",
    hubspot_table : "dashboard/operational/hubspot-activity",
    insights_outreach_stage: 'dashboard/operational/outreach-stage',
 }