import React, { useState, useEffect } from "react";
import { Layout, Menu, Dropdown } from "antd";
import moment from "moment-timezone";
import logo from "../assets/images/logo_1.png";
import green_dot from "../assets/images/green_dot.svg";
import "./index.scss";
import { MenuFoldOutlined, CloseCircleOutlined, BellOutlined, MoreOutlined } from "@ant-design/icons";
import { useMountEffect } from "../hooks";
import axios from "axios";
import { API_END_POINTS } from "../api_end_points";
import UserService from "../services/UserService";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

const { Header } = Layout;

interface HeaderComponentProps {
	hideMenu: boolean;
	menuToggle: (arg0: boolean) => void;
}

export const HeaderComponent: React.FC<HeaderComponentProps> = (props) => {
	const { t } = useTranslation();
	const [sideBarMenuKey, setSideBarMenuKey] = useState<any>();
	const [userName, setUserName] = useState<any>();
	const [updatedTime, setUpdatedTime] = useState<any>();
	const history = useHistory();

	const {
		data: lastUpdatedTime,
		isError,
		error,
	} = useQuery(
		["lastUpdateData"],
		() => axios.get(API_END_POINTS.get_updated_time),
		{
			refetchIntervalInBackground: true,
			refetchInterval: 1000 * 60 * 15,
		}
	);

	useEffect(() => {
		if (lastUpdatedTime?.data) {
			setUpdatedTime(moment.unix(lastUpdatedTime.data.updatedDate).fromNow());
		}
	}, [lastUpdatedTime]);

	const marketingMenu: any = [
		// {
		// 	name: t("layout_full_page.stratagic"),
		// 	key: "/marketing/strategic",
		// 	disable: true,
		// },
		{
			name: t("layout_full_page.tactical"),
			key: "/marketing/tactical",
			disable: false,
		},
		{
			name: t("layout_full_page.operational"),
			key: "/marketing/operational",
			disable: false,
		},
	];

	useMountEffect(() => {
		var username: any = UserService.getUsername();
		var name: any = UserService.getName();
		var email: any = UserService.getEmail();
		setUserName(name ? name : username ? username : email ? email : "NA");
		// axios
		// 	.get(API_END_POINTS.get_updated_time)
		// 	.then((res) => {
		// 		setUpdatedTime(moment.tz(res.data, "Asia/Kolkata").format("dddd, MM/DD/YYYY, HH:mm A z"));
		// 	})
		// 	.catch((err) => {
		// 		console.log(err);
		// 	});
	});

	useEffect(() => {
		if (window.location.pathname === "/" || window.location.pathname === "/marketing/tactical" || window.location.pathname === "/marketing/strategic" || window.location.pathname === "/marketing/operational") {
			setSideBarMenuKey(marketingMenu);
		}
	}, [window.location.pathname]);

	const handleMenuClick = (e: any) => {
		if (e.key.includes("/marketing")) {
			setSideBarMenuKey(marketingMenu);
		}
		history.push(e.key);
		props.menuToggle(false);
	};

	const toggle = () => {
		props.menuToggle(!props.hideMenu);
	};

	const onLogout = () => {
		sessionStorage.clear();
		UserService.doLogout();
	};

	return (
		<Header className="header">
			<div className="row m-0">
				<div className="col d-flex pl-0">
					<div className="bg-darkgrey text-white d-flex justify-content-center align-items-center" style={{ width: "50px", height: "50px", fontSize: "1.3rem" }}>
						{!props.hideMenu ? <MenuFoldOutlined className="" onClick={() => toggle()} /> : <CloseCircleOutlined onClick={() => toggle()} />}
					</div>
					<div style={{ width: "100px" }}>
						<img src={logo} alt="Clever Tap" className="img-fluid px-2" />
					</div>
					<div className="pl-3" style={{ borderLeft: "1px solid rgba(106,111,128,0.28)" }}>
						<div className="font-weight-bold text-truncate" style={{ fontSize: "18px" }}>
							{t("layout_full_page.marketing_dashboard")}
						</div>
					</div>
				</div>
				<div className="col-auto d-none d-sm-none d-md-none d-lg-none d-xl-block">
					<Menu
						theme="light"
						className="text-uppercase font-weight-bold menu-text d-flex justify-content-center"
						mode="horizontal"
						defaultSelectedKeys={[window.location.pathname === "/" ? "/marketing/tactical" : window.location.pathname]}
						onClick={handleMenuClick}
					>
						{sideBarMenuKey &&
							sideBarMenuKey.map((obj: any) => {
								return (
									<Menu.Item className="font-weight-bold" key={obj.key} disabled={obj.disable}>
										{obj.name}
									</Menu.Item>
								);
							})}
					</Menu>
				</div>
				<div className="col text-right d-flex align-items-center justify-content-end">
					<div className="pr-3 font-weight-bold f-12 d-none d-sm-none d-md-block d-lg-block d-xl-block">
						<img src={green_dot} style={{ paddingRight: "5px" }} />
						{t("layout_full_page.last_updated")} : {updatedTime && updatedTime}
					</div>
					<BellOutlined className="bell-out pr-3" />
					<Dropdown
						overlay={
							<Menu>
								<Menu.Item className="font-weight-bold">{userName}</Menu.Item>
								<Menu.Divider className="d-none d-sm-block d-md-none d-lg-none d-xl-none" />
								<Menu.Item className="d-none d-sm-block d-md-none d-lg-none d-xl-none">{updatedTime && updatedTime}</Menu.Item>
								<Menu.Item onClick={() => onLogout()}>{t("layout_full_page.logout")}</Menu.Item>
							</Menu>
						}
						trigger={["click"]}
					>
						{/* <UserOutlined style={{fontSize: '1.5rem'}}/> */}
						<MoreOutlined style={{ fontSize: "1rem" }} />
						{/* <img src={avatar} className="rounded-circle mr-2" width="30" height="30" onClick={() => UserService.doLogout()}></img> */}
					</Dropdown>
				</div>
			</div>
		</Header>
	);
};
